import { Component, OnInit } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'infinitude-panel';
  constructor(private afMessaging: AngularFireMessaging) {

  }

  ngOnInit() {

    this.afMessaging.messages.subscribe((messaging: any) => {
      console.log(messaging)
      messaging.onMessage((message: any) => {
        console.log('Message received:', message);
        // Handle the received message here
      });
    });

  }

  
}
